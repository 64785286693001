<template>
  <div class="new_comment">
    <div class="all_Statistics" v-if="module_type != 4 || module_type != 5">
      <p class="fw600 fs18 mb32" v-if="module_type != 1 && module_type != 6 && module_type != 8">全部{{ typeName }} （{{
        total
      }}）</p>
      <p class="fw600 fs20 mb40" style="color: #1a1a1a;" v-if="module_type == 8">全部{{ typeName }} ({{ total }})</p>
      <ul class="uu" v-if="module_type == 1 || module_type == 6">

        <!-- //全部评论 和我的评论 -->
        <li v-for="(item, index) in cgList" :key="index" class="cursor" @click="onSearchCg(item.id)">
          <img :src="item.id == active ? item.icon1 : item.icon2" alt="" />
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>

    <div class="comment_detail">
      <div class="comment_block" v-for="(item, index) in commentListData" :key="index">
        <div class="block_one">
          <div class="images">
            <img :src="item.photo_url" />
          </div>
          <div class="one_word">
            <p>{{ item.username }}</p>
            <p>{{ item.create_time }}</p>
          </div>
        </div>
        <div class="block_two">
          {{ item.content }}
        </div>

        <!-- //是否显示点赞 -->
        <div class="block_three" v-if="isToken">
          <div class="three_one cursor" v-if="item.user_id == user_id" @click="deleteComment(item.id)">
            <i class="iconfont iconshanchu"></i>
            <span>删除</span>
          </div>
          <div class="three_one cursor" @click="giveUP(item, item.id)">
            <i class="iconfont icondianzan"></i>
            <span>点赞({{ item.like_count }})</span>
          </div>
          <div class="three_two cursor" @click="showComment(item)">
            <i class="iconfont iconpinglun" :style="{ color: item.show == '1' ? '' : '#06A7FF' }"></i>
            <span :style="{ color: item.show == '1' ? '' : '#06A7FF' }">{{ item.show == "1" ? "回复" : "收起" }}({{
              item.reply_count
            }})</span>
          </div>
        </div>

        <!-- 这个是评论框  2隐藏-->
        <transition>
          <div class="yourElement comment_block_reply" v-if="item.show == '2'">
            <div class="block">
              <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="item.reply_one" resize="none"></el-input>
              <div v-if="item_status != 2" class="release cursor" @click="oneReply(item)">发布</div>
            </div>
          </div>
        </transition>
        <!-- end -->

        <div class="block_four">
          <transition>
            <!-- 这里是评论循环 -->
            <div class="reply_block yourElement" v-if="item.replyBlock && item.replyBlock.data != 0 && item.show == '2'
              ">
              <div class="reply_block_one" v-for="(item2, index2) in item.replyBlock.data" :key="index2">
                <div class="reply_one">
                  <div class="images">
                    <img :src="item2.photo_url" />
                  </div>
                  <div class="reply_one_word">
                    <p>
                      <span>{{ item2.username }}</span>
                      <span>回复</span>
                      <span>{{ item2.reply_username }}</span>
                    </p>
                    <p>{{ item.create_time }}</p>
                  </div>
                </div>
                <div class="reply_two">
                  {{ item2.content }}
                </div>
                <div class="reply_three">
                  <div class="three_one cursor" @click="deleteComment(item2.id)" v-if="item2.user_id == user_id">
                    <i class="iconfont iconshanchu"></i>
                    <span>删除</span>
                  </div>
                  <div class="three_one cursor" @click="giveUP(item2, item2.id)">
                    <i class="iconfont icondianzan"></i>
                    <span>点赞({{ item2.like_count }})</span>
                  </div>
                  <div class="three_two cursor" @click="showComment(item2)">
                    <i class="iconfont iconpinglun" :style="{ color: item2.show == '1' ? '' : '#06A7FF' }"></i>
                    <span :style="{ color: item2.show == '1' ? '' : '#06A7FF' }">{{ item2.show == "1" ? "回复" : "收起"
                    }}</span>
                  </div>
                </div>
                <div class="reply_five" v-if="item2.show == '2'">
                  <div class="reply_five_block">
                    <el-input type="textarea" :rows="2" :placeholder="'回复' + item2.username" v-model="item2.reply_two"
                      resize="none"></el-input>
                    <div v-if="item_status != 2" class="release_block cursor" @click="twoReply(item, item2)">发布</div>
                  </div>
                </div>
                <div class="reply_four">
                  <div class="reply_four_hengxian"></div>
                </div>
              </div>
              <div class="load_more">
                <!-- <el-button plain>查看更多>></el-button> -->
                <el-pagination small layout="prev, pager, next" :total="item.replyBlock.total"
                  :page-size="item.replyBlock.per_page" background @current-change="changeInnerPage($event, item)">
                </el-pagination>
              </div>
            </div>
            <!-- 评论循环end -->
          </transition>
        </div>

        <div class="outer_block_five">
          <div class="block_five"></div>
        </div>
      </div>
      <div class="outer_load" v-if="commentListData.length != 0">
        <!-- <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="form.page"
            :page-size="form.limit"
            layout="prev, pager, next"
            :total="total">
          </el-pagination> -->

        <el-pagination background @size-change="handleOuterSizeChange" @current-change="handleOuterCurrentChange"
          :current-page.sync="page" :page-size="limit" layout=" prev, pager, next" :total="total">
        </el-pagination>
      </div>
    </div>

    <!-- 当没有数据时显示暂无数据  -->
    <div v-if="commentListData.length == 0" class="noData">暂无{{ typeName }}数据</div>
  </div>
</template>


<script>
import {
  commentList,
  ReplyList,
  TopicReplay,
  //   ReplayClickLike,
  DeleteTopic,
} from "@/api/teacherStudio";
export default {
  props: {
    // 状态
    item_status: {
      type: [String, Number],
      default: "",
    },
    //活动、话题id
    activity_id: {
      type: [String, Number],
      default: "",
    },
    //环节id
    link_id: {
      type: [String, Number],
      default: "",
    },
    //模块类型
    //  1教研活动模块 
    //  2研讨社区模块 
    //  3工作室话题模块 
    //  4研修视频评论 
    //  5工作室录播课程评论 
    //  6工作室教研活动模块 
    //  7工作室文章评论
    //  8教研直播心得评论
    //  9活动报道

    module_type: {
      type: Number,
      default: 1,
    },
    // 刷新此组件
    random: {
      // type:Number
      type: Number,
      // default: 0,
    },
    is_from: {
      type: Number,
      default: 1,
    },

    // 活动环节参与权限字段数据
    operation_status: {
      type: Boolean,
      default: true,
    },
    typeName: {
      type: String,
      default: "评论",
    },
  },
  data() {
    return {

      //是否有token
      isToken: false,

      // 外层每页条数
      limit: 10,
      // 外层页数
      page: 1,
      // 外层数据总数目
      total: 0,
      // 0全部 1我的
      is_mine: 0,
      // 评论列表数据
      commentListData: [],
      // 当前用户id
      user_id: "",
      active: 0,
      cgList: [
        {
          name: `全部${this.typeName}`,
          id: 0,
          icon1: require("@/assets/images/teacherStudio/had_select.png"),
          icon2: require("@/assets/images/teacherStudio/un_select.png"),
        },
        {
          name: `我的${this.typeName}`,
          id: 1,
          icon1: require("@/assets/images/teacherStudio/had_select.png"),
          icon2: require("@/assets/images/teacherStudio/un_select.png"),
        },
      ],
      is_personid: "",
    };
  },
  created() {

  },
  mounted() {
    if (this.$route.query.is_personid) {
      this.is_personid = this.$route.query.is_personid
    } else {
      this.is_personid = 1
    }
    this.init();
  },
  watch: {
    random() {
      this.getCommentList();
    },
    is_mine() {
      this.conditonSearch();
    },
  },
  methods: {
    // 初始化函数
    init() {
      this.user_id = window.localStorage.getItem("id");
      this.getCommentList();
      if (this.module_type == 4 || this.module_type == 5) {
        //我的评论
        this.getMyCommentInfo();
      }
      this.isToken = localStorage.getItem("token") == null ? false : true;
    },
    // 我的评论(研修视频,四名工作室录播课程具有)
    getMyCommentInfo() {
      var obj = {
        params: {
          limit: this.limit,
          page: this.page,
          user_id: this.user_id,
          is_personid: this.is_personid,
        },
      };

      if (this.module_type == 1) {
        let obj = {
          limit: 10,
          page: 1,
          activity_id: this.activity_id,
          link_id: this.link_id,
          is_mine: 1,
          is_personid: this.is_personid,
        }
        this.$axios.get('ActivityReply/comment_list', { params: obj }).then((res) => {
          this.commentListData = res.data.data.data;
          this.total = res.data.data.total;
          this.$emit("onChangeTotal", this.total, 2);
          //评论回复
          this.commentListData.forEach((item) => {
            let obj1 = {
              limit: 500000,
              page: 1,
              activity_id: this.activity_id,
              top_reply_id: item.id,
              link_id: this.link_id,
              is_personid: this.is_personid,
            }
            this.$set(item, "show", "1");
            this.$axios.get("ActivityReply/reply_list", { params: obj1 }).then((list) => {
            })
          })
        })
      }
      if (this.module_type == 4) {
        obj.params.course_id = this.activity_id;
        obj.is_personid = this.is_personid;
        this.$axios.get("courseReply/get_my_comment_list", obj).then((res) => {
          var resdata = res.data;
          this.commentListData = resdata.data.data;
          this.$emit("onChangeTotal", resdata.data.total, 2);
          this.total = resdata.data.total;
          this.commentListData.forEach(async (item) => {
            var obj = {
              limit: 5000000,
              page: 1,
              course_id: this.activity_id,
              top_reply_id: item.id,
              is_personid: this.is_personid,
            };
            this.$set(item, "show", "1");
            this.$axios.get("courseReply/get_reply_list", { params: obj }).then((res2) => {
              this.$set(item, "replyBlock", res2.data.data);
              item.replyBlock.per_page = parseInt(item.replyBlock.per_page);
              item.replyBlock.data.forEach((item2) => {
                this.$set(item2, "show", "1");
              });
            });
          });
        });
      } else if (this.module_type == 5) {
        obj.params.teaching_studio_course_id = this.activity_id;
        this.$axios
          .get("TeachingStudioCourseReply/get_my_comment_list", obj)
          .then((res) => {
            this.commentListData = res.data.data.data;
            this.$emit("onChangeTotal", res.data.data.total, 2);
            this.total = res.data.data.total;
            this.commentListData.forEach(async (item) => {
              var obj = {
                limit: 5000000,
                page: 1,
                teaching_studio_course_id: this.activity_id,
                top_reply_id: item.id,
                is_personid: this.is_personid,
              };
              this.$set(item, "show", "1");
              this.$axios
                .get("TeachingStudioCourseReply/get_reply_list", {
                  params: obj,
                })
                .then((res2) => {
                  this.$set(item, "replyBlock", res2.data.data);
                  item.replyBlock.per_page = parseInt(item.replyBlock.per_page);
                  item.replyBlock.data.forEach((item2) => {
                    this.$set(item2, "show", "1");
                  });
                });
            });
          });
      } else if (this.module_type == 6) {
        let obj = {
          params: {
            limit: this.limit,
            page: this.page,
            activity_id: this.activity_id,
            link_id: this.link_id,
            //	0全部 1我的
            is_mine: 1,
            is_personid: this.is_personid,
          },
        };
        this.onDiscussComment(obj);
      }
    },
    // 请求后台获取所有评论数据
    getCommentList() {
      if (this.module_type == "1") {
        var obj = {
          params: {
            limit: this.limit,
            page: this.page,
            activity_id: this.activity_id,
            link_id: this.link_id,
            is_mine: this.is_mine,
            is_personid: this.is_personid,
          },
        };
        this.$axios.get("ActivityReply/comment_list", obj).then((res) => {
          var resdata = res.data;
          this.total = resdata.data.total;
          this.$emit("onTotal", this.total);
          this.commentListData = resdata.data.data;
          this.commentListData.forEach((item) => {
            var obj = {
              limit: 5,
              page: 1,
              activity_id: this.activity_id,
              top_reply_id: item.id,
              link_id: this.link_id,
              is_personid: this.is_personid,
            };
            this.$set(item, "show", "1");
            this.$axios
              .get("ActivityReply/reply_list", { params: obj })
              .then((res2) => {
                this.$set(item, "replyBlock", res2.data.data);
                item.replyBlock.per_page = parseInt(item.replyBlock.per_page);
                item.replyBlock.data.forEach((item2) => {
                  this.$set(item2, "show", "1");
                });
              });
          });
        });
      } else if (this.module_type == "2") {
        let obj = {
          params: {
            id: this.activity_id,
            limit: this.limit,
            page: this.page,
            is_personid: this.is_personid,
          },
        };
        this.$axios.get("gambitComment/list", obj).then((res) => {
          var resdata = res.data;
          this.total = resdata.data.total;
          this.commentListData = resdata.data.data;
          this.commentListData.forEach((item) => {
            var obj = {
              limit: 5,
              page: 1,
              id: this.activity_id,
              reply_id: item.id,
              is_personid: this.is_personid,
            };
            this.$set(item, "show", "1");
            this.$axios
              .get("gambitReply/list", { params: obj })
              .then((res2) => {
                this.$set(item, "replyBlock", res2.data.data);
                item.replyBlock.per_page = parseInt(item.replyBlock.per_page);
                item.replyBlock.data.forEach((item2) => {
                  this.$set(item2, "show", "1");
                });
              });
          });
        });
      } else if (this.module_type == "3") {
        let obj = {
          id: this.activity_id,
          limit: this.limit,
          page: this.page,
          is_personid: this.is_personid,
        };
        commentList(obj).then((res) => {
          this.total = res.data.total;
          this.commentListData = res.data.data;
          this.commentListData.forEach((item) => {
            this.$set(item, "show", "1");
            var obj = {
              limit: 5,
              page: 1,
              id: this.activity_id,
              reply_id: item.id,
              is_personid: this.is_personid,
            };
            ReplyList(obj).then((res2) => {
              this.$set(item, "replyBlock", res2.data);
              item.replyBlock.per_page = parseInt(item.replyBlock.per_page);
              item.replyBlock.data.forEach((item2) => {
                this.$set(item2, "show", "1");
              });
            });
          });
        });
      } else if (this.module_type == "4") {
        let obj = {
          params: {
            course_id: this.activity_id,
            limit: this.limit,
            page: this.page,
            is_personid: this.is_personid,
          },
        };
        this.$axios.get("courseReply/get_comment_list", obj).then((res) => {
          this.$emit("onChangeTotal", res.data.data.total, 1);
          this.total = res.data.data.total;
          this.commentListData = res.data.data.data;
          this.commentListData.forEach((item) => {
            this.$set(item, "show", "1");
            var obj = {
              limit: 5000000,
              page: 1,
              course_id: this.activity_id,
              top_reply_id: item.id,
              is_personid: this.is_personid,
            };
            this.$axios
              .get("courseReply/get_reply_list", { params: obj })
              .then((res2) => {
                this.$set(item, "replyBlock", res2.data.data);
                item.replyBlock.per_page = parseInt(item.replyBlock.per_page);
                item.replyBlock.data.forEach((item2) => {
                  this.$set(item2, "show", "1");
                });
              });
          });
        });
      } else if (this.module_type == "5") {
        let obj = {
          params: {
            teaching_studio_course_id: this.activity_id,
            limit: this.limit,
            page: this.page,
            is_personid: this.is_personid,
          },
        };
        this.$axios
          .get("TeachingStudioCourseReply/get_comment_list", obj)
          .then((res) => {
            this.$emit("onChangeTotal", res.data.data.total, 1);
            this.total = res.data.data.total;
            this.commentListData = res.data.data.data;
            this.commentListData.forEach((item) => {
              this.$set(item, "show", "1");
              var obj = {
                limit: 5000000,
                page: 1,
                teaching_studio_course_id: this.activity_id,
                top_reply_id: item.id,
                is_personid: this.is_personid,
              };
              this.$axios
                .get("TeachingStudioCourseReply/get_reply_list", {
                  params: obj,
                })
                .then((res2) => {
                  this.$set(item, "replyBlock", res2.data.data);
                  item.replyBlock.per_page = parseInt(item.replyBlock.per_page);
                  item.replyBlock.data.forEach((item2) => {
                    this.$set(item2, "show", "1");
                  });
                });
            });
          });
      } else if (this.module_type == "6") {
        let obj = {
          params: {
            limit: this.limit,
            page: this.page,
            activity_id: this.activity_id,
            link_id: this.link_id,
            //	0全部 1我的
            is_mine: 0,
            is_personid: this.is_personid,
          },
        };
        this.onDiscussComment(obj);
      } else if (this.module_type == "7") {
        let obj = {
          params: {
            teaching_studio_article_id: this.activity_id,
            limit: this.limit,
            page: this.page,
            is_personid: this.is_personid,
          },
        };
        this.$axios
          .get("teachingStudioArticleReply/get_comment_list", obj)
          .then((res) => {
            this.$emit("onChangeTotal", res.data.data.total, 1);
            this.total = res.data.data.total;
            this.commentListData = res.data.data.data;
            this.commentListData.forEach((item) => {
              this.$set(item, "show", "1");
              var obj = {
                limit: 5000000,
                page: 1,
                teaching_studio_article_id: this.activity_id,
                top_reply_id: item.id,
                is_personid: this.is_personid,
              };
              this.$axios
                .get("teachingStudioArticleReply/get_reply_list", {
                  params: obj,
                })
                .then((res2) => {
                  this.$set(item, "replyBlock", res2.data.data);
                  item.replyBlock.per_page = parseInt(item.replyBlock.per_page);
                  item.replyBlock.data.forEach((item2) => {
                    this.$set(item2, "show", "1");
                  });
                });
            });
          });
      } else if (this.module_type == "8") { //直播评论心得
        let obj = {
          params: {
            live_id: this.$route.query.liveid,
            limit: this.limit,
            page: this.page,
            is_personid: this.is_personid,
          },
        };
        //评论列表
        this.$axios.get("liveReply/get_comment_list", obj)
          .then((res) => {
            // this.$emit("onChangeTotal", res.data.data.total, 1);
            this.total = res.data.data.total;
            this.commentListData = res.data.data.data;
            this.commentListData.forEach((item) => {
              this.$set(item, "show", "1");
              //评论回复
              var obj = {
                limit: 5000000,
                page: 1,
                live_id: this.$route.query.liveid,
                top_reply_id: item.id,
                is_personid: this.is_personid,
              };
              this.$axios.get("liveReply/get_reply_list", { params: obj, })
                .then((res2) => {
                  this.$set(item, "replyBlock", res2.data.data);
                  item.replyBlock.per_page = parseInt(item.replyBlock.per_page);
                  item.replyBlock.data.forEach((item2) => {
                    this.$set(item2, "show", "1");
                  });
                });
            });
          });
      } else if (this.module_type == "9") {
        let obj = {
          params: {
            teaching_studio_report_id: this.activity_id,
            limit: this.limit,
            page: this.page,
            is_personid: this.is_personid,
          },
        };
        this.$axios
          .get("teachingStudioReportReply/get_comment_list", obj)
          .then((res) => {
            this.$emit("onChangeTotal", res.data.data.total, 1);
            this.total = res.data.data.total;
            this.commentListData = res.data.data.data;
            this.commentListData.forEach((item) => {
              this.$set(item, "show", "1");
              var obj = {
                limit: 5000000,
                page: 1,
                teaching_studio_report_id: this.activity_id,
                top_reply_id: item.id,
                is_personid: this.is_personid,
              };
              this.$axios
                .get("teachingStudioReportReply/get_reply_list", {
                  params: obj,
                })
                .then((res2) => {
                  this.$set(item, "replyBlock", res2.data.data);
                  item.replyBlock.per_page = parseInt(item.replyBlock.per_page);
                  item.replyBlock.data.forEach((item2) => {
                    this.$set(item2, "show", "1");
                  });
                });
            });
          });
      }
    },

    onCallBack() {
      if (this.active == 1) {  //我的评论
        this.getMyCommentInfo();
      } else {  //全部评论
        this.getCommentList();
      }
    },
    // 全部/我的
    onSearchCg(id) {
      // id 0 全部评论     1我的评论
      this.active = id;
      this.page = 1;
      this.onCallBack();
      //   let obj = {
      //     params: {
      //       limit: this.limit,
      //       page: this.page,
      //       activity_id: this.activity_id,
      //       link_id: this.link_id,
      //       //	0全部 1我的
      //       is_mine: id,
      //     },
      //   };
      //   this.onDiscussComment(obj);
    },
    // 四名工作室活动模块讨论环节的全部评论
    onDiscussComment(obj) {
      this.$axios
        .get("TeachingStudioActivityReply/comment_list", obj)
        .then((res) => {
          this.$emit("onChangeTotal", res.data.data.total, 1);
          this.total = res.data.data.total;
          this.$emit("onTotal", this.total);
          this.commentListData = res.data.data.data;
          this.commentListData.forEach((item) => {
            this.$set(item, "show", "1");
            var obj = {
              limit: 5000000,
              page: 1,
              activity_id: this.activity_id,
              top_reply_id: item.id,
              link_id: this.link_id,
              is_personid: this.is_personid,
            };
            this.$axios
              .get("TeachingStudioActivityReply/reply_list", { params: obj })
              .then((res2) => {
                this.$set(item, "replyBlock", res2.data.data);
                item.replyBlock.per_page = parseInt(item.replyBlock.per_page);
                item.replyBlock.data.forEach((item2) => {
                  this.$set(item2, "show", "1");
                });
              });
          });
        });
    },
    // 按条件搜索（页数置为1）
    conditonSearch() {
      this.page = 1;
      this.getCommentList();
    },

    // 外层每页条数改变触发的函数
    handleOuterSizeChange(val) {
      this.limit = val;
      if (this.module_type == 4 || this.module_type == 5) {
        this.$emit("onGetComment");
      } else {
        this.getCommentList();
      }
    },
    // 外层页数改变时触发的函数
    handleOuterCurrentChange(val) {
      this.page = val;
      if (this.module_type == 4) {
        this.$emit("onGetComment");
      } else {
        this.getCommentList();
      }
    },
    // 请求后台删除相应的评论
    deleteComment(id) {
      console.log(this.is_personid);
      if (this.module_type == "1") {
        this.$axios.delete(`ActivityReply/${id}?is_personid=${this.is_personid}`).then((res) => {
          this.$message.success("删除成功");
          this.getCommentList();
        });
      } else if (this.module_type == "2") {

        // http://hlwjy.co/delGambitReply/{id}/{user_id}/{is_personid}
        // this.$axios.delete(`gambit/${id}/${this.user_id}?is_personid=${this.is_personid}`).then((res) => {
        this.$axios.delete(`delGambitReply/${id}/${this.user_id}/${this.is_personid}`).then((res) => {
          this.$message.success("删除成功");
          this.getCommentList();
        });
      } else if (this.module_type == "3") {
        DeleteTopic({ ids: id, teaching_studio_id: localStorage.getItem("studioId") ? localStorage.getItem("studioId") : 0 }).then((res) => {
          this.$message.success("删除成功");
          this.getCommentList();
        });
      } else if (this.module_type == "4") {
        this.$axios.delete(`courseReply/del_reply/${id}?is_personid=${this.is_personid}`).then((res) => {
          this.$message.success("删除成功");
          this.getCommentList();
          this.getMyCommentInfo();
        });
      } else if (this.module_type == "5") {
        this.$axios
          .delete(`TeachingStudioCourseReply/del_reply/${id}?is_personid=${this.is_personid}`)
          .then((res) => {
            this.$message.success("删除成功");
            this.getCommentList();
            this.getMyCommentInfo();
          });
      } else if (this.module_type == "6") {
        this.$axios.delete(`TeachingStudioActivityReply/${id}?is_personid=${this.is_personid}`).then((res) => {
          this.$message.success("删除成功");
          this.onCallBack();
        });
      } else if (this.module_type == "7") {
        this.$axios
          .delete(`teachingStudioArticleReply/del_reply/${id}?is_personid=${this.is_personid}`)
          .then((res) => {
            this.$message.success("删除成功");
            this.getCommentList();
          });
      } else if (this.module_type == "8") { //教研直播删除评论
        this.$axios
          .delete(`liveReply/del_reply/${id}?is_personid=${this.is_personid}`)
          .then((res) => {
            this.$message.success("删除成功");
            this.getCommentList();
          });
      } else if (this.module_type == "9") {
        this.$axios
          .delete(`teachingStudioReportReply/del_reply/${id}?is_personid=${this.is_personid}`)
          .then((res) => {
            this.$message.success("删除成功");
            this.getCommentList();
          });
      }
    },
    // 请求后台点赞函数
    giveUP(item, id) {
      let p = null;
      if (this.module_type == "1") {
        var obj = {
          user_id: this.user_id,
          reply_id: id,
          is_personid: this.is_personid,
        };
        p = this.$axios.post("ActivityReply/insertLike", obj);
      } else if (this.module_type == "2") {
        var obj = {
          reply_id: id,
          is_personid: this.is_personid,
        };
        p = this.$axios.post("gambitReply/set_like", obj);
      } else if (this.module_type == "3") {
        var obj = {
          reply_id: id,
          is_personid: this.is_personid,
        };
        p = this.$axios.post("TeachingStudio/gambitReply/set_like", obj);
      } else if (this.module_type == "4") {
        var obj = {
          user_id: this.user_id,
          reply_id: id,
          is_personid: this.is_personid,
        };
        p = this.$axios.post("courseReply/insertLike", obj);
      } else if (this.module_type == "5") {
        var obj = {
          reply_id: id,
          is_personid: this.is_personid,
        };
        p = this.$axios.post("TeachingStudioCourseReply/insertLike", obj);
      } else if (this.module_type == "6") {
        var obj = {
          reply_id: id,
          user_id: this.user_id,
          is_personid: this.is_personid,
        };
        p = this.$axios.post("TeachingStudioActivityReply/insertLike", obj);
      } else if (this.module_type == "7") {
        var obj = {
          reply_id: id,
          form: 2,
          is_personid: this.is_personid,
        };
        p = this.$axios.post("teachingStudioArticleReply/insertLike", obj);
      }
      else if (this.module_type == "8") { //直播心得评论点赞
        var obj = {
          reply_id: id,
          is_personid: this.is_personid,
        };
        p = this.$axios.post("liveReply/insertLike", obj);
      } else if (this.module_type == "9") {
        var obj = {
          reply_id: id,
          form: 2,
          is_personid: this.is_personid,
        };
        p = this.$axios.post("teachingStudioReportReply/insertLike", obj);
      }
      p.then((res) => {
        let str = res.data.data.msg;
        if (this.module_type == "3") {
          str = res.data.msg;
        }
        // this.$message.success(str);
        if (str == "点赞成功") {
          item.like_count = item.like_count + 1;
        } else if (str == "取消点赞成功") {
          item.like_count = item.like_count - 1;
        }

        if (this.module_type == "8") {
          this.$message.success("操作成功")
          //点赞成功刷新
          this.getCommentList();
        }

      });
    },
    // 评论框的显示与隐藏函数
    showComment(item) {
      if (item.show == "1") {
        item.show = "2";
      } else if (item.show == "2") {
        item.show = "1";
      }
    },
    // 请求后台活动环节回复函数
    postReply(obj) {
      obj['is_personid'] = this.is_personid;
      if (this.module_type == "1") {
        if (this.operation_status === true) {
          this.$axios.post("ActivityReply/insertReply", obj).then((res) => {
            // this.$message.success(res.data.data.msg);
            this.$message.success("回复成功");
            this.getCommentList();
          });
        } else {
          this.$message.info("无参与权限");
        }
      } else if (this.module_type == "2") {
        this.$axios.post("gambitReply/set_reply", obj).then((res) => {
          this.$message.success(res.data.msg);
          this.getCommentList();
        });
      } else if (this.module_type == "3") {
        TopicReplay(obj).then((res) => {
          this.$message.success("回复成功");
          this.getCommentList();
        });
      } else if (this.module_type == "4") {
        this.$axios.post("courseReply/insert_reply", obj).then((res) => {
          this.$message.success("回复成功");
          this.getCommentList();
          this.getMyCommentInfo();
        });
      } else if (this.module_type == "5") {
        this.$axios
          .post("TeachingStudioCourseReply/insert_reply", obj)
          .then((res) => {
            this.$message.success("回复成功");
            this.getCommentList();
            this.getMyCommentInfo();
          });
      } else if (this.module_type == "6") {
        this.$axios
          .post("TeachingStudioActivityReply/insertReply", obj)
          .then((res) => {
            this.$message.success("回复成功");
            this.onCallBack();
          });
      } else if (this.module_type == "7") {
        this.$axios
          .post("teachingStudioArticleReply/insert_reply", obj)
          .then((res) => {
            this.$message.success("回复成功");
            this.getCommentList();
          });
      } else if (this.module_type == "8") { //直播评论回复
        this.$axios
          .post("liveReply/insert_reply", obj)
          .then(() => {
            this.$message.success("回复成功");
            this.getCommentList();
          });
      } else if (this.module_type == "9") {
        this.$axios
          .post("teachingStudioReportReply/insert_reply", obj)
          .then((res) => {
            this.$message.success("回复成功");
            this.getCommentList();
          });
      }
    },
    // 第一层评论回复函数
    oneReply(item) {
      if (this.module_type == "1") {
        var obj = {
          activity_id: this.activity_id,
          user_id: this.user_id,
          reply_user_id: item.user_id,
          top_reply_id: item.id,
          content: item.reply_one,
          link_id: this.link_id,
          is_personid: this.is_personid,
        };
      } else if (this.module_type == "2" || this.module_type == "3") {
        var obj = {
          gambit_id: this.activity_id,
          user_id: this.user_id,
          content: item.reply_one,
          reply_user_id: item.user_id,
          top_reply_id: item.id,
          is_personid: this.is_personid,
        };
      } else if (this.module_type == "4") {
        var obj = {
          course_id: this.activity_id,
          user_id: this.user_id,
          reply_user_id: item.user_id,
          top_reply_id: item.id,
          content: item.reply_one,
          is_personid: this.is_personid,
          is_personid: this.is_personid,
        };
      } else if (this.module_type == "5") {
        var obj = {
          teaching_studio_course_id: this.activity_id,
          user_id: this.user_id,
          reply_user_id: item.user_id,
          top_reply_id: item.id,
          content: item.reply_one,
          is_personid: this.is_personid,
        };
      } else if (this.module_type == "6") {
        var obj = {
          activity_id: this.activity_id,
          user_id: this.user_id,
          reply_user_id: item.user_id,
          top_reply_id: item.id,
          content: item.reply_one,
          link_id: this.link_id,
          is_personid: this.is_personid,
        };
      } else if (this.module_type == "7") {
        var obj = {
          teaching_studio_article_id: this.activity_id,
          user_id: this.user_id,
          reply_user_id: item.user_id,
          top_reply_id: item.id,
          content: item.reply_one,
          link_id: this.link_id,
          is_personid: this.is_personid,
        };
      } else if (this.module_type == "8") { //直播评论
        var obj = {
          live_id: this.$route.query.liveid,
          content: item.reply_one,
          reply_user_id: item.user_id,
          top_reply_id: item.id,
          is_personid: this.is_personid,
        };
      } else if (this.module_type == "9") {
        var obj = {
          teaching_studio_report_id: this.activity_id,
          user_id: this.user_id,
          reply_user_id: item.user_id,
          top_reply_id: item.id,
          content: item.reply_one,
          link_id: this.link_id,
          is_personid: this.is_personid,
        };
      }
      this.postReply(obj);
    },
    // 里层评论回复函数
    twoReply(item, item2) {
      if (this.module_type == "1") {
        var obj = {
          activity_id: this.activity_id,
          user_id: this.user_id,
          reply_user_id: item2.user_id,
          top_reply_id: item.id,
          content: item2.reply_two,
          link_id: this.link_id,
          is_personid: this.is_personid,
        };
      } else if (this.module_type == "2" || this.module_type == "3") {
        var obj = {
          gambit_id: this.activity_id,
          user_id: this.user_id,
          content: item2.reply_two,
          reply_user_id: item2.user_id,
          top_reply_id: item.id,
          is_personid: this.is_personid,
        };
      } else if (this.module_type == "4") {
        var obj = {
          course_id: this.activity_id,
          user_id: this.user_id,
          reply_user_id: item2.user_id,
          top_reply_id: item.id,
          content: item2.reply_two,
          is_personid: this.is_personid,
        };
      } else if (this.module_type == "5") {
        var obj = {
          teaching_studio_course_id: this.activity_id,
          user_id: this.user_id,
          reply_user_id: item2.user_id,
          top_reply_id: item.id,
          content: item2.reply_two,
          is_personid: this.is_personid,
        };
      } else if (this.module_type == "6") {
        var obj = {
          activity_id: this.activity_id,
          user_id: this.user_id,
          reply_user_id: item2.user_id,
          top_reply_id: item.id,
          content: item2.reply_two,
          link_id: this.link_id,
          is_personid: this.is_personid,
        };
      } else if (this.module_type == "7") {
        var obj = {
          teaching_studio_article_id: this.activity_id,
          user_id: this.user_id,
          reply_user_id: item2.user_id,
          top_reply_id: item.id,
          content: item2.reply_two,
          link_id: this.link_id,
          is_personid: this.is_personid,
        };
      } else if (this.module_type == "9") {
        var obj = {
          teaching_studio_report_id: this.activity_id,
          user_id: this.user_id,
          reply_user_id: item2.user_id,
          top_reply_id: item.id,
          content: item2.reply_two,
          link_id: this.link_id,
          is_personid: this.is_personid,
        };
      }
      this.postReply(obj);
    },
    // 里层切换页数触发的函数
    async changeInnerPage(val, item) {
      let obj = {};
      if (this.module_type == "1") {
        obj = {
          limit: 5,
          page: val,
          activity_id: this.activity_id,
          top_reply_id: item.id,
          link_id: this.link_id,
          is_personid: this.is_personid,
        };
      } else if (this.module_type == "2" || this.module_type == "3") {
        obj = {
          limit: 5,
          page: val,
          id: this.activity_id,
          reply_id: item.id,
          is_personid: this.is_personid,
        };
      }
      //   else if (this.module_type == "4") {
      //     obj = {
      //       limit: 5,
      //       page: val,
      //       course_id: this.activity_id,
      //       top_reply_id: item.id,
      //     };
      //   }

      let newdata = await this.getReplyListData(obj);
      item.replyBlock.total = newdata.total;
      item.replyBlock.data = newdata.data;
      item.replyBlock.data.forEach((item2) => {
        this.$set(item2, "show", "1");
      });
    },
    // 请求后台获取相应回复列表数据
    async getReplyListData(obj) {
      obj['is_personid'] = this.is_personid;
      let res = {};
      if (this.module_type == "1") {
        res = await this.$axios.get("ActivityReply/reply_list", {
          params: obj,
        });
      } else if (this.module_type == "2") {
        res = await this.$axios.get("gambitReply/list", { params: obj });
      } else if (this.module_type == "3") {
        res = await this.$axios.get("TeachingStudio/gambitReply/list", {
          params: obj,
        });
      }
      //   else if (this.module_type == "4") {
      //     res = await this.$axios.get("courseReply/get_reply_list", {
      //       params: obj,
      //     });
      //   }
      return res.data.data;
    },
  },
};
</script>

<style lang="less" scoped>
.new_comment {

  //暂无数据样式
  .noData {
    width: 100%;
    height: 100px;
    text-align: center;
    line-height: 100px;
    font-size: 15px;
  }

  .all_Statistics {
    color: #1a1a1a;

    .uu {
      display: flex;
      align-items: center;
      margin-bottom: 32px;

      li {
        margin-right: 10%;

        >img {
          vertical-align: text-bottom;
          margin-right: 16px;
        }
      }
    }
  }

  .comment_detail {
    .comment_block {
      margin-bottom: 20px;

      .block_one {
        display: flex;
        align-items: center;
        margin-bottom: 4px;

        .images {
          margin-right: 14px;
          border-radius: 100%;
          overflow: hidden;
          width: 50px;
          height: 50px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .one_word {
          font-size: 14px;

          p:nth-child(1) {
            color: #fa8c15;
            margin-bottom: 3px;
          }

          p:nth-child(2) {
            color: #9a9ea5;
          }
        }
      }

      .block_two {
        font-size: 16px;
        color: #333333;
        margin-left: 64px;
        margin-bottom: 6px;
      }

      .block_three {
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        color: #9a9ea5;
        margin-bottom: 10px;

        .three_one {
          margin-right: 26px;
        }

        i {
          margin-right: 6px;
        }
      }

      .block_four {
        display: flex;
        justify-content: flex-end;
        margin-left: 64px;

        .reply_block {
          width: 100%;
          // height: 542px;
          background: #f7f7f7;
          // background: #f4f4f5;
          border-radius: 8px;
          box-sizing: border-box;
          //   padding-left:30px;
          padding: 30px 27px 0px 30px;

          .reply_block_one {
            .reply_one {
              display: flex;
              align-items: center;
              margin-bottom: 4px;

              .images {
                margin-right: 14px;
                width: 50px;
                height: 50px;
                border-radius: 100%;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              .reply_one_word {
                font-size: 14px;

                p:nth-child(1) {
                  margin-bottom: 3px;

                  span:nth-child(odd) {
                    color: #fa8c15;
                  }

                  span:nth-child(2) {
                    margin: 0px 10px;
                  }
                }

                p:nth-child(2) {
                  color: #9a9ea5;
                }
              }
            }

            .reply_two {
              color: #333333;
              margin-left: 64px;
              margin-bottom: 6px;
            }

            .reply_three {
              display: flex;
              justify-content: flex-end;
              font-size: 14px;
              color: #9a9ea5;
              margin-bottom: 10px;

              .three_one {
                margin-right: 26px;
              }

              i {
                margin-right: 6px;
              }
            }

            .reply_four {
              display: flex;
              justify-content: flex-end;
              margin-bottom: 30px;
              margin-left: 64px;

              .reply_four_hengxian {
                // width: 794px;
                width: 100%;
                // height: 1px;
                border-top: 1px dashed #ececec;
              }
            }

            .reply_five {
              display: flex;
              //   justify-content: flex-end;
              margin-bottom: 25px;
              margin-left: 64px;

              .reply_five_block {
                width: 100%;
                background: #ffffff;
                border: 1px solid #ececec;
                border-radius: 8px;
                padding: 14px;

                .release_block {
                  width: 92px;
                  height: 36px;
                  background: #fa8c15;
                  border-radius: 4px;
                  color: #ffffff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  float: right;
                }

                /deep/ .el-textarea__inner {
                  padding: 0;
                  border: none;
                }
              }
            }
          }

          .reply_block_one:nth-last-child(2) {
            .reply_four {
              margin-bottom: 0px;

              .reply_four_hengxian {
                border-top: 0px;
              }
            }
          }

          .load_more {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
          }

          // .load_more >>>.el-pagination.is-background
          //   .el-pager
          //   li:not(.disabled).active {
          //   background-color: #fd7a3a; // 进行修改背景和字体
          //   color: #fff;
          // }
        }
      }

      .outer_block_five {
        margin-left: 64px;

        .block_five {
          width: 100%;
          height: 1px;
          border-top: 1px dashed #ececec;
          //margin-top: 26px;
          // box-sizing: border-box;
        }
      }

      .comment_block_reply {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 15px;

        .block {
          width: 100%;
          background: #ffffff;
          border: 1px solid #ececec;
          border-radius: 8px;
          padding: 14px;
          margin-left: 64px;

          .release {
            width: 92px;
            height: 36px;
            background: #fa8c15;
            border-radius: 4px;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            float: right;
          }

          /deep/ .el-textarea__inner {
            padding: 0;
            border: none;
          }
        }
      }
    }

    .comment_block:nth-last-child(2) {
      .block_five {
        border-top: 0px;
        margin-top: 0px;
      }
    }

    .outer_load {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
  }

  .yourElement {
    animation-duration: 0s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
  }
}
</style>

<style lang="less">
.load_more .el-pagination.is-background .el-pager li {
  background: #f7f7f7;
}
</style>